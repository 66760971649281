body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  position: relative;
}

#root {
  min-height: 100vh;
}

.root-container {
  min-height: 100vh;
  display: flex;
  flex: 1 1;
  flex-direction: column;
}